import ALL_CREDIT_CARDS from '@/graphql/credit-cards/query/getAllCreditCardsAutocomplete.gql'

const creditCards = ref([])
const key = ref(0)
const firstTime = ref(true)

export async function creditCardsAutocomplete(params: any) {
  const apolloQuery = ALL_CREDIT_CARDS
  const variables = {
    search: params.search ? params.search : null,
    orderBy: [
      {
        column: 'CREATED_AT',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  if (!variables.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchCreditCardsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await creditCardsAutocomplete({ search: queryString })
    cb(results?.getAllCreditCards?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCreditCardsCb')
  }
}

export function getSearchCreditCards(entityCreditCard: any, entityCreditCards: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchCreditCards('', entityCreditCard, entityCreditCards)
  }
  return creditCards?.value ?? []
}

export async function searchCreditCards(queryString: any, entityCreditCard: any, entityCreditCards: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await creditCardsAutocomplete({ search: queryString })
    const remoteCreditCards = results?.getAllCreditCards?.data?.map((i: any) => {
      return {
        label: i.last_four_digits,
        value: i.uuid
      }
    }) ?? []
    const creditCardsToAdd: { label: any; value: any }[] = []
    entityCreditCards?.forEach((j: any) => {
      if (!remoteCreditCards.find((k: any) => k.value === j.uuid)) {
        creditCardsToAdd.push({
          label: j.last_four_digits,
          value: j.uuid
        })
      }
    })
    if (entityCreditCard && !remoteCreditCards.find((k: any) => k.value === entityCreditCard.uuid)) {
      creditCardsToAdd.push({
        label: entityCreditCard.last_four_digits,
        value: entityCreditCard.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    creditCards.value = [...remoteCreditCards, ...creditCardsToAdd]
    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCreditCards')
  }
}

export function getCreditCardKey () {
  return key.value
}

export function initialCreditCards () {
  firstTime.value = true
  key.value = 0
  creditCards.value = []
}